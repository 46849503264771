import p70 from "./70";
import p71 from "./71";
import p72 from "./72";
import p73 from "./73";
import p74 from "./74";
import p75 from "./75";
import p76 from "./76";
import p77 from "./77";

const pageQuestions = {
  6: p70,
  7: p71,
  8: p72,
  9: p73,
  10: p74,
  11: p75,
  12: p76,
  13: p77,
};

export default pageQuestions;
