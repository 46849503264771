export const dataBook = {
  1: [[{ imgUrl: "img/FriendsPlus/ListPage/1.jpg", excerciseNum: 0 }]],
  2: [[{ imgUrl: "img/FriendsPlus/ListPage/2.jpg", excerciseNum: 0 }]],
  3: [[{ imgUrl: "img/FriendsPlus/ListPage/3.jpg", excerciseNum: 0 }]],
  4: [[{ imgUrl: "img/FriendsPlus/ListPage/4.jpg", excerciseNum: 0 }]],
  5: [[{ imgUrl: "img/FriendsPlus/ListPage/5.jpg", excerciseNum: 0 }]],
  6: [
    [{ imgUrl: "img/FriendsPlus/Page70/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page70/2.jpg", excerciseNum: 6 },
      { imgUrl: "img/FriendsPlus/Page70/3.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page70/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page70/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page70/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page70/7.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page70/8.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page70/9.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page70/10.jpg", excerciseNum: 4 },
      { imgUrl: "img/FriendsPlus/Page70/11.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page70/12.jpg", excerciseNum: 5 },
      { imgUrl: "img/FriendsPlus/Page70/13.jpg", excerciseNum: 0 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page70/14.jpg", excerciseNum: 0 }],
  ],

  7: [[{ imgUrl: "img/FriendsPlus/ListPage/71.jpg", excerciseNum: 0 }]],

  8: [
    [{ imgUrl: "img/FriendsPlus/Page72/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page72/2.jpg", excerciseNum: 6 },
      { imgUrl: "img/FriendsPlus/Page72/3.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page72/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page72/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page72/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page72/7.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page72/8.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page72/9.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page72/10.jpg", excerciseNum: 4 },
      { imgUrl: "img/FriendsPlus/Page72/11.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page72/12.jpg", excerciseNum: 5 },
      { imgUrl: "img/FriendsPlus/Page72/13.jpg", excerciseNum: 0 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page72/14.jpg", excerciseNum: 0 }],
  ],
  9: [
    [{ imgUrl: "img/FriendsPlus/Page73/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page73/2.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page73/3.jpg", excerciseNum: 1 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page73/4.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page73/5.jpg", excerciseNum: 2 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page73/6.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page73/7.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page73/8.jpg", excerciseNum: 4 },
      { imgUrl: "img/FriendsPlus/Page73/9.jpg", excerciseNum: 0 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page73/10.jpg", excerciseNum: 0 }],
  ],
  10: [
    [{ imgUrl: "img/FriendsPlus/Page74/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page74/2.jpg", excerciseNum: 6 },
      { imgUrl: "img/FriendsPlus/Page74/3.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page74/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page74/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page74/6.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page74/7.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page74/8.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page74/9.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page74/10.jpg", excerciseNum: 4 },
      { imgUrl: "img/FriendsPlus/Page74/11.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page74/12.jpg", excerciseNum: 5 },
      { imgUrl: "img/FriendsPlus/Page74/13.jpg", excerciseNum: 0 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page74/14.jpg", excerciseNum: 0 }],
  ],
  11: [
    [{ imgUrl: "img/FriendsPlus/Page75/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page75/2.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page75/3.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page75/4.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page75/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page75/6.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page75/7.jpg", excerciseNum: 5 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page75/8.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page75/9.jpg", excerciseNum: 6 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page75/10.jpg", excerciseNum: 0 }],
  ],
  12: [
    [{ imgUrl: "img/FriendsPlus/Page76/1.jpg", excerciseNum: 0 }],
    [{ imgUrl: "img/FriendsPlus/Page76/2.jpg", excerciseNum: 5 }],
    [
      { imgUrl: "img/FriendsPlus/Page76/3.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page76/4.jpg", excerciseNum: 4 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page76/5.jpg", excerciseNum: 2 },
      { imgUrl: "img/FriendsPlus/Page76/6.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page76/7.jpg", excerciseNum: 3 },
      { imgUrl: "img/FriendsPlus/Page76/8.jpg", excerciseNum: 0 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page76/9.jpg", excerciseNum: 0 }],
  ],
  13: [
    [{ imgUrl: "img/FriendsPlus/Page77/1.jpg", excerciseNum: 0 }],
    [
      { imgUrl: "img/FriendsPlus/Page77/2.jpg", excerciseNum: 5 },
      { imgUrl: "img/FriendsPlus/Page77/3.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page77/4.jpg", excerciseNum: 1 },
      { imgUrl: "img/FriendsPlus/Page77/5.jpg", excerciseNum: 0 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page77/6.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page77/7.jpg", excerciseNum: 2 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page77/8.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page77/9.jpg", excerciseNum: 3 },
    ],
    [
      { imgUrl: "img/FriendsPlus/Page77/10.jpg", excerciseNum: 0 },
      { imgUrl: "img/FriendsPlus/Page77/11.jpg", excerciseNum: 4 },
    ],
    [{ imgUrl: "img/FriendsPlus/Page77/12.jpg", excerciseNum: 0 }],
  ],
};
